.fc-view-harness {
    background-color: #fff;
}

.fc-event-time,
.fc-event-title {
    padding: 0 1px;
}

.fc-event-time {
    white-space: nowrap;
}

.fc-title,
.fc-event-title {
    white-space: normal;
}

.fc .fc-daygrid-event-harness {
    border-bottom: 1px solid #eaeaea;
}